import project_1 from '../assets/images/project_1.png';
import project_2 from '../assets/images/project_2.png';
import project_3 from '../assets/images/project_3.png';
import project_4 from '../assets/images/project_4.png';
import project_5 from '../assets/images/project_5.png';
import project_6 from '../assets/images/project_6.png';

export const PROJECTS = [
    {
        image: project_1,
        title: 'EatChat Mobile App',
        details: `Connecting people with shared cravings to meet, chat and eat while building new relationships through food`,
        link: `https://eatchatonline.com `
    },
    {
        image: project_2,
        title: 'Fieldboard Mobile App',
        details: `Field officers perform captain and guarantor verification, asset recoveries and emergency services using this app within Gigmile`,
        link: `https://play.google.com/store/apps/details?id=com.fieldboard.app&hl=en_US&pli=1`
    },
    {
        image: project_3,
        title: 'Gigmile Admin Dashboard',
        details: `Overseeing the management of captains and their guarantors, service requests, tracking of repayment and monitoring of deployed assets`,
        link: `https://gigmile.com `
    },
    {
        image: project_4,
        title: 'Mochant Mobile App',
        details: `Helping Mobile Money Merchants to manage their businesses with ease by performing transactions and keeping all records`,
        link: `https://mochantapp.com`
    },
    {
        image: project_5,
        title: 'Fieldboard Admin Dashboard',
        details: `Managing captain and guarantor verification, tracking of asset recoveries and emergency services through the Fieldboard Administrative Dashboard`,
        link: `https://gigmile.com`
    },
    {
        image: project_6,
        title: 'Yi Logistics Website',
        details: `A shipping and logistics firm involved in conveying goods and packages securely between China and Ghana`,
        link: `https://yilogisticsgh.com `
    }
]