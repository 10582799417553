import zentech from '../assets/images/zentech.png';
import awa from '../assets/images/awa.png';
import gigmile from '../assets/images/gigmile.png';
import yi_shipping from '../assets/images/yi_shipping.png';
import eat_chat from '../assets/images/eat_chat.png';

export const COMPANIES = [
    {
        alt: 'Zentech',
        image: zentech
    },
    {
        alt: 'awa',
        image: awa
    },
    {
        alt: 'gigmile',
        image: gigmile
    },
    {
        alt: 'yi_shipping',
        image: yi_shipping
    },
    {
        alt: 'eat chat',
        image: eat_chat
    },
    {
        alt: 'Zentech',
        image: zentech
    },
    {
        alt: 'awa',
        image: awa
    },
    {
        alt: 'gigmile',
        image: gigmile
    },
    {
        alt: 'yi_shipping',
        image: yi_shipping
    },
    {
        alt: 'eat chat',
        image: eat_chat
    },
    {
        alt: 'Zentech',
        image: zentech
    },
    {
        alt: 'awa',
        image: awa
    },
    {
        alt: 'gigmile',
        image: gigmile
    },
    {
        alt: 'yi_shipping',
        image: yi_shipping
    },
    {
        alt: 'eat chat',
        image: eat_chat
    },
    {
        alt: 'Zentech',
        image: zentech
    },
    {
        alt: 'awa',
        image: awa
    },
    {
        alt: 'gigmile',
        image: gigmile
    },
    {
        alt: 'yi_shipping',
        image: yi_shipping
    },
    {
        alt: 'eat chat',
        image: eat_chat
    }
]