import behance from '../assets/icons/behance.png';
import dribble from '../assets/icons/dribble.png';
import figma from '../assets/icons/figma.png';
import gmail from '../assets/icons/gmail.png';
import gumroad from '../assets/icons/gumroad.png';
import instagram from '../assets/icons/instagram.png';
import linkedin from '../assets/icons/linkedin.png';
import x from '../assets/icons/x.png';

export const SOCIALS = [
    {
        img: linkedin,
        name: 'Linkedin',
        link_to: 'https://www.linkedin.com/in/joshua-gavu-434357160/'
    },
    {
        img: behance,
        name: 'Behance',
        link_to: 'https://behance.net/joshuagavu'
    },
    {
        img: dribble,
        name: 'Dribbble',
        link_to: 'https://dribbble.com/MrGavu'
    },
    {
        img: x,
        name: 'X',
        link_to: 'https://twitter.com/JoshuaGavu'
    },
    {
        img: instagram,
        name: 'Instagram',
        link_to: 'https://instagram.com/joshuagavu'
    },
    {
        img: figma,
        name: 'Figma Community',
        link_to: 'https://figma.com/@joshuagavu'
    },
    {
        img: gumroad,
        name: 'Gumroad',
        link_to: 'https://gavujoshua.gumroad.com/'
    },
    {
        img: gmail,
        name: 'Gmail',
        link_to: 'mailto:gavujoshua@gmail.com'
    }
]